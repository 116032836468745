// no longer actually an accordion

import React from "react";
import { Container, Text } from "@atoms";
import { ContainedAccordionItem } from "@molecules";
import AccordionPattern from "@svg/AccordionPattern";

const ContainedAccordion = ({ heading, description, accordions }) => {
  return (
    <section className="my-10">
      <Container variant="sm" smallerPadding>
        <div className="relative flex flex-col justify-center overflow-hidden rounded-3xl bg-purple py-8 sm:flex-row sm:py-0">
          <div className="relative z-10 w-full max-w-none shrink-0 px-4 py-0 sm:max-w-[18rem] sm:px-6 sm:py-16">
            <Text variant="h3" className="text-white">
              {heading}
            </Text>
            <Text variant="sm" className="my-6 text-white">
              {description}
            </Text>
          </div>
          <div className="relative z-10 flex flex-col gap-y-6 px-4 py-0 sm:px-6 sm:py-16">
            {accordions?.map((item, i) => {
              return <ContainedAccordionItem {...item} />;
            })}
          </div>
          <AccordionPattern className="absolute -bottom-20 left-0 z-0 w-80 text-teal sm:w-[27.5rem]" />
        </div>
      </Container>
    </section>
  );
};

ContainedAccordion.defaultProps = {};

export default ContainedAccordion;
